.btn{
    &.ico-btn{
        padding: 0 7px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
}
.virtual_matches_list{
    .table>:not(caption)>*>*{
        background-color: transparent !important;
        padding: 0;
      
    }
    .table{
        .contest_header{
            td,th{
                &:nth-child(2){
                    width: auto;
                }
            }
        }
        td,th{
            &:first-child{
                width: 50px;
            }
            &:nth-child(2){
                width: 80px;
                
            }
            &:nth-child(3){
                width: 145px;
                padding-left: 8px;
            }
            &:nth-child(4){
                width: 150px;
            }
            &:nth-child(5){
                width: 150px;
                padding-left: 5px;
            }
            &:nth-child(6){
                width: 150px;
                padding-left: 5px;
            }
            &:nth-child(7){
                width: 130px;
            }
            &:nth-child(8){
                width: 200px;
            }
            &:nth-child(9){
                width: auto;
                padding-left: 8px;
            }
            &:nth-child(10){
                width: 50px;
            }
        }
    }
}